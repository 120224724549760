"use client";

import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { auth } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { Button, TextField, Typography, Grid, Card, Link as MuiLink } from "@mui/material";
import { createUserWithEmailAndPassword, getRedirectResult, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { useTranslation } from "react-i18next";

type Inputs = {
  email: string;
  password: string;
};

const Register = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
  const { t } = useTranslation(); 
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // クライアントIDを取得

  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          navigate("/");
        }
      })
      .catch((error) => {
        console.error(error);
        alert("認証中にエラーが発生しました");
      });
  }, [navigate]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
      console.log(userCredential);
      navigate("/auth/login");
    } catch (error: any) {
      console.error(error);
      alert(error.message);
    }
  };

  const googleAuth = () => {
    const provider = new GoogleAuthProvider();
    if (googleClientId) {
      provider.setCustomParameters({ client_id: googleClientId });
    }
    signInWithRedirect(auth, provider);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh", backgroundColor: "#f0f0f0" }}>
      <Card style={{ padding: "20px", borderRadius: "10px", boxShadow: "0 0 10px rgba(0,0,0,0.1)", width: "100%", maxWidth: "500px" }}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center" gutterBottom>
            {t("register")}
          </Typography>
          <Button fullWidth variant="outlined" onClick={googleAuth} style={{ marginBottom: "20px" }}>
            {t("register_with_google")}
          </Button>

          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label={t("email_address")}
              autoComplete="email"
              {...register("email", { required: t("email_is_required") })}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label={t("password")}
              type="password"
              autoComplete="current-password"
              {...register("password", { required: t("password_is_required"), minLength: { value: 6, message: t("password_min_length") } })}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
            />
            <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: "20px", marginBottom: "20px" }}>
              {t("register")}
            </Button>
          </form>

          <Grid container justifyContent="flex-end">
            <Typography variant="body2" component="div" style={{ marginTop: "10px" }}>
              {t("already_have_an_account")}{" "}
              <MuiLink href="/auth/login" underline="hover" color="primary">
                {t("go_to_login_page")}
              </MuiLink>
            </Typography>
            <MuiLink href="/rule/privacy-policy" variant="body2" style={{ marginTop: "10px", display: "block", textAlign: "center" }}>
              {t("google_api_policy_notice")}
            </MuiLink>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default Register;

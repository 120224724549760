"use client";

import React, { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // URLからパラメータを取得＆ページ遷移用
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { db } from "../config/firebase";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

type Character = {
  id: string;
  name: string;
  nameJapanese: string;
  mangaTitle: string;
  imageBigURL: string;
  rank: number;
};

export const MangaPage: FC = () => {
  const { title } = useParams<{ title: string }>(); // URLから漫画タイトルを取得
  const [characters, setCharacters] = useState<Character[]>([]);
  const navigate = useNavigate(); // ページ遷移用のフック

  useEffect(() => {
    if (title) {
      const fetchCharacters = async () => {
        const characterCollectionRef = collection(db, "characters");
        const q = query(
          characterCollectionRef,
          where("mangaTitle", "==", title), // 漫画タイトルでフィルタリング
          orderBy("rank")
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const newCharacters: Character[] = snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            nameJapanese: doc.data().nameJapanese,
            mangaTitle: doc.data().mangaTitle,
            imageBigURL: doc.data().imageBigURL,
            rank: doc.data().rank,
          }));
          setCharacters(newCharacters);
        });

        return () => {
          unsubscribe();
        };
      };

      fetchCharacters();
    }
  }, [title]);

  // キャラクター選択時のページ遷移
  const handleCharacterClick = (characterId: string) => {
    navigate(`/character/${characterId}`); // キャラクター詳細ページに遷移
  };

  return (
    <div className="bg-white h-full p-6">
      <h1 className="text-4xl font-bold mb-4">{title}</h1> {/* 漫画タイトルを表示 */}
      <ImageList sx={{ width: "100%", gap: 2 }} cols={6}>
        {characters.map((character) => (
          <ImageListItem key={character.id}>
            {/* キャラクター画像クリックで詳細ページに遷移 */}
            <img
              src={`${character.imageBigURL}?w=100&fit=crop&auto=format`}
              alt={character.name}
              loading="lazy"
              style={{
                cursor: "pointer",
                width: "100%",
                height: "150px",
                objectFit: "cover",
              }}
              onClick={() => handleCharacterClick(character.id)}
            />
            {/* キャラクター名前クリックで詳細ページに遷移 */}
            <ImageListItemBar
              title={
                <span
                  onClick={() => handleCharacterClick(character.id)}
                  style={{ cursor: "pointer", color: "#007bff" }}
                >
                  {character.nameJapanese}
                </span>
              }
              subtitle={
                <span
                  onClick={() => handleCharacterClick(character.id)}
                  style={{ cursor: "pointer", color: "#007bff" }}
                >
                  {character.name}
                </span>
              }
              position="below"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

export default MangaPage;

"use client";

import React, { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

type Character = {
  id: string;
  name: string;
  nameJapanese: string;
  mangaTitle: string;
  imageBigURL?: string;
  rank: number;
};

export const SearchPage: FC = () => {
  const { word } = useParams<{ word: string }>();
  const [characters, setCharacters] = useState<Character[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (word) {
      const fetchCharacters = async () => {
        const characterCollectionRef = collection(db, "characters");

        const snapshot = await getDocs(characterCollectionRef);
        const filteredCharacters = snapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() } as Character))
          .filter((character) =>
            character.name.includes(word) || 
            character.nameJapanese.includes(word) || 
            character.mangaTitle.includes(word)
          );

        setCharacters(filteredCharacters);
      };

      fetchCharacters();
    }
  }, [word]);

  const handleCharacterClick = (characterId: string) => {
    navigate(`/character/${characterId}`);
  };

  return (
    <div className="bg-white h-full p-6">
      <h1 className="text-4xl font-bold mb-4">Search Results for: {word}</h1>
      <ImageList sx={{ width: "100%", gap: 2 }} cols={6}>
        {characters.map((character) => (
          <ImageListItem key={character.id}>
            {character.imageBigURL ? (
              <img
                src={`${character.imageBigURL}?w=100&fit=crop&auto=format`}
                alt={character.name}
                loading="lazy"
                style={{
                  cursor: "pointer",
                  width: "100%",
                  height: "150px",
                  objectFit: "contain", // "cover"から"contain"に変更
                  backgroundColor: "#f0f0f0", // 余白が見える場合の背景色
                }}
                onClick={() => handleCharacterClick(character.id)}
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "150px",
                  backgroundColor: "#f0f0f0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleCharacterClick(character.id)}
              >
                <span>No Image</span>
              </div>
            )}
            <ImageListItemBar
              title={
                <span
                  onClick={() => handleCharacterClick(character.id)}
                  style={{ cursor: "pointer", color: "#007bff" }}
                >
                  {character.nameJapanese}
                </span>
              }
              subtitle={
                <span
                  onClick={() => handleCharacterClick(character.id)}
                  style={{ cursor: "pointer", color: "#007bff" }}
                >
                  {character.name} - {character.mangaTitle}
                </span>
              }
              position="below"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

export default SearchPage;

import {
  Home as HomeIcon,
  Category as CategoryIcon,
  Favorite as FavoriteIcon,
  BusinessCenter as BusinessCenterIcon, // ビジネスアイコン
  Gavel as GavelIcon,
  Security as SecurityIcon,
} from "@mui/icons-material";
import {
  NavigationItem,
  NavigationSingleItem,
  NavigationGroupItem
} from "../NavigationList";
import { useTranslation } from 'react-i18next';
import { t } from "i18next";

const createNavigationSingleItem = (item: NavigationSingleItem) => item;
const createNavigationGroupItem = (item: NavigationGroupItem) => item;

export const NAVIGATION_ITEMS: NavigationItem[] = [
  createNavigationSingleItem({
    icon: HomeIcon,
    title: 'Ranking',
    href: "/characters",
  }),
  createNavigationSingleItem({
    icon: CategoryIcon,
    title: 'Category',
    href: "/mangalist",
  }),

  // createNavigationSingleItem({
  //   icon: FavoriteIcon,
  //   title: 'Favorite',
  //   href: "/favorite"
  // }),
  createNavigationSingleItem({
    icon: BusinessCenterIcon, // ビジネス用アイコン
    title: 'Business Inquiry', // 「Contact」から変更
    href: "http://zero-one.llc/?page_id=1579",
  }),
  createNavigationSingleItem({
    icon: SecurityIcon, // プライバシー関連のアイコン
    title: 'Privacy',
    href: "/rule/privacy-policy",
  }),
  createNavigationSingleItem({
    icon: GavelIcon, // 利用規約関連のアイコン
    title: 'Rule',
    href: "/rule/term-of-service",
  }),
];

import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../config/firebase";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Button,
  Card,
  CardContent,
  IconButton,
  SelectChangeEvent,
} from "@mui/material";
import { FaTwitter, FaFacebook, FaLinkedin, FaInstagram, FaShareAlt } from "react-icons/fa";
import { getAuth } from "firebase/auth";

// 仮のuserIdをここで定義します。実際には認証などで取得したユーザーIDを使います。
const userId = "actual-user-id"; // ここを適切なuserIdに変更

type Character = {
  id: string;
  name: string;
  nameJapanese: string;
  mangaTitle: string;
  description: string;
  imageBigURL: string;
  chatContent: string;
};

export const CharacterPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [character, setCharacter] = useState<Character | null>(null);
  const [parsedDescription, setParsedDescription] = useState<string>("");
  const [relationship, setRelationship] = useState<string>("設定なし");

  useEffect(() => {
    const fetchCharacter = async () => {
      if (id) {
        const docRef = doc(db, "characters", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const characterData = docSnap.data() as Character;
          console.log(characterData);
          setCharacter({ ...characterData, id });
        }
      }
    };
    fetchCharacter();
  }, [id]);

  if (!character) {
    return <div>Loading...</div>;
  }

  // ルーム作成の関数
  const handleCreateRoom = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
  
    if (!currentUser) {
      console.error("User is not authenticated");
      window.location.href = "/auth/register"; // 認証されていない場合はサインインページにリダイレクト
      return;
    }
  
    try {
      const roomId = `${character.id}-${currentUser.uid}`; // currentUser.uid を使って動的に設定
      const roomRef = doc(db, "rooms", roomId);
  
      await setDoc(roomRef, {
        userId: currentUser.uid, // ハードコーディングの "actual-user-id" ではなく currentUser.uid を使用
        characterId: character.id,
        name: character.name, // ルーム名としてキャラクター名を使用
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
  
      console.log("Room created successfully");
      window.location.href = `/chat/${character.id}`;
    } catch (error) {
      console.error("Error creating room:", error);
    }
  };

  // 画像クリック時にルームを選択する関数
  const selectRoom = (characterId: string, characterName: string) => {
    console.log(`Room selected: ${characterId}, ${characterName}`);
    // 必要に応じて、ここでルーム選択処理を追加できます
  };

  // SelectChangeEventを使って型指定
  const handleRelationshipChange = (event: SelectChangeEvent<string>) => {
    setRelationship(event.target.value as string);
  };

  const shareUrl = `https://chatbot.zero-one.llc/character/${character.id}`;
  const shareText = `${character.nameJapanese}とチャットしよう！AIが驚きの会話をお届けします。`;

  return (
    <Box sx={{ p: 4, maxWidth: "800px", mx: "auto", textAlign: "center", bgcolor: "#f9f9f9", borderRadius: 2 }}>
      <Card sx={{ boxShadow: 3 }}>
        {/* 画像を直接表示する <img> タグに変更 */}
        <img
          src={`${character.imageBigURL}?w=100&fit=crop&auto=format`}
          alt={character.name}
          loading="lazy"
          style={{
            cursor: "pointer",
            width: "100%",
            height: "150px",
            objectFit: "cover",
          }}
        />
        <CardContent>
          <Typography variant="h4" fontWeight="bold" sx={{ mt: 2 }}>
            {character.nameJapanese} ({character.name})
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 2 }}>
            {character.mangaTitle}
          </Typography>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: parsedDescription }}
            sx={{ my: 2, color: "text.primary", textAlign: "left" }}
          ></Typography>

          <FormControl fullWidth sx={{ mt: 2, mb: 3 }}>
            <Select
              value={relationship}
              onChange={handleRelationshipChange}
              displayEmpty
              sx={{ textAlign: "left", backgroundColor: "#fff" }}
            >
              <MenuItem value="設定なし">設定なし</MenuItem>
              {/* 他の関係性の選択肢 */}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            sx={{ width: "100%", mb: 2 }}
            onClick={handleCreateRoom} // handleCreateRoomを呼び出す
          >
            Chat with {character.name}
          </Button>

          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
              Share on Social Media
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <IconButton
                href={`https://twitter.com/share?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`}
                target="_blank"
              >
                <FaTwitter color="#1DA1F2" size={28} />
              </IconButton>
              <IconButton
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
                target="_blank"
              >
                <FaFacebook color="#3b5998" size={28} />
              </IconButton>
              <IconButton
                href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(shareText)}`}
                target="_blank"
              >
                <FaLinkedin color="#0077B5" size={28} />
              </IconButton>
              <IconButton href="https://www.instagram.com/" target="_blank">
                <FaInstagram color="#C13584" size={28} />
              </IconButton>
              <IconButton href={shareUrl} target="_blank">
                <FaShareAlt color="#595959" size={28} />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CharacterPage;

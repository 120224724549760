"use client";

import React, { FC, useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import { useAppContext } from "../context/AppContext";
import { Link } from "react-router-dom";

type Character = {
  id: number;
  nameJapanese: string;
  name: string;
  imageBigURL: string;
  mangaTitle: string;
};

export const FavoritesPage: FC = () => {
  const { userId } = useAppContext();
  const [favoriteCharacters, setFavoriteCharacters] = useState<Character[]>([]);

  useEffect(() => {
    const fetchFavorites = async () => {
      if (userId) {
        const userDocRef = doc(db, "users", userId);
        const userDocSnap = await getDoc(userDocRef);
        
        if (userDocSnap.exists()) {
          const favorites = userDocSnap.data()?.favorites || [];

          const fetchedFavorites = await Promise.all(
            favorites.map(async (rank: number) => {
              const characterDocRef = doc(db, "characters", String(rank));
              const characterDocSnap = await getDoc(characterDocRef);

              if (characterDocSnap.exists()) {
                return characterDocSnap.data() as Character;
              }
              return null;
            })
          );
          setFavoriteCharacters(fetchedFavorites.filter(Boolean) as Character[]);

        }
      }
    };

    fetchFavorites();
  }, [userId]);

  if (favoriteCharacters.length === 0) {
    return <div>No favorites yet.</div>;
  }

  return (
    <div className="bg-white h-full p-6">
      <h1 className="text-3xl font-bold mb-4">Your Favorites</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {favoriteCharacters.map((favorite) => (
          <div key={favorite.id} className="bg-gray-200 p-4 rounded-lg">
            <img
              src={favorite.imageBigURL}
              alt={favorite.name}
              className="w-32 h-32 mb-4 rounded-lg"
            />
            <h2 className="text-xl font-bold">{favorite.nameJapanese} ({favorite.name})</h2>
            <p className="text-lg">{favorite.mangaTitle}</p>
            <Link
              to={`/character/${favorite.id}`}
              className="text-blue-500 underline"
            >
              View Character
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FavoritesPage;

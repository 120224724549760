import { FC } from "react";
import { Routes, Route } from "react-router-dom";

import { Layout } from "./layouts/Layout";

import { CharacterPage } from "./pages/CharacterPage";
import { SettingPlanPage } from "./pages/SettingPlanPage";
import { SettingNotificationDesktopPage } from "./pages/SettingNotificationDesktopPage";
import { SettingNotificationEmailPage } from "./pages/SettingNotificationEmailPage";
import ForgotPassword from './pages/ForgotPassword';
import { Login } from './pages/Login';
import Register from './pages/Register';
import Unsubscribe from './pages/Unsubscribe';
import { PrivacyPolicyPage } from './pages/PrivacyPolicy';
import { TermOfServicePage } from './pages/TermOfService';

import { FavoritesPage } from "./pages/FavoritesPage";
// 新しいページをインポート
import { CharacterListPage } from "./pages/CharacterListPage";
import { MangaListPage } from "./pages/MangaListPage";
import { MangaPage } from "./pages/MangaPage";
import { SearchPage } from "./pages/SearchPage";
import { Chat } from "./pages/Chat";

export const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<CharacterListPage />} />
        <Route path="/characters" element={<CharacterListPage />} />
        <Route path="/character/:id" element={<CharacterPage />} />
        <Route path="/mangalist" element={<MangaListPage />} />
        <Route path="/manga/:title" element={<MangaPage />} /> 
        <Route path="/search/:word" element={<SearchPage />} /> 
        <Route path="/chat/:roomId" element={<Chat />} /> {/* チャットルームページ */}
        <Route path="/favorite" element={<FavoritesPage />} />

        <Route path="/settings/plan" element={<SettingPlanPage />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/register" element={<Register />} />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/unsubscribe" element={<Unsubscribe />} />
        <Route path="/rule/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/rule/term-of-service" element={<TermOfServicePage />} />
      </Route>
    </Routes>
  );
};

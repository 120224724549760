"use client";

import React, { FC, useEffect, useState } from "react";
import { collection, onSnapshot, query, orderBy, limit } from "firebase/firestore";
import { useNavigate } from "react-router-dom"; // ページ遷移用のフック
import { db } from "../config/firebase";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

type Character = {
  id: string;
  mangaTitle: string;
};

export const MangaListPage: FC = () => {
  const [mangaTitles, setMangaTitles] = useState<string[]>([]);
  const navigate = useNavigate(); // ページ遷移用のフック

  useEffect(() => {
    const fetchMangaTitles = async () => {
      const characterCollectionRef = collection(db, "characters");
      const q = query(characterCollectionRef, orderBy("rank"), limit(300));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const mangaTitleSet = new Set<string>();
        snapshot.docs.forEach((doc) => {
          const characterData = doc.data() as Character;
          mangaTitleSet.add(characterData.mangaTitle); // 重複を避けるためにSetを使用
        });
        setMangaTitles(Array.from(mangaTitleSet)); // SetをArrayに変換して保存
      });

      return () => {
        unsubscribe();
      };
    };

    fetchMangaTitles();
  }, []);

  // 漫画タイトルをクリックした時のページ遷移
  const handleMangaClick = (mangaTitle: string) => {
    navigate(`/manga/${mangaTitle}`); // 漫画タイトルでフィルタリングされたページに遷移
  };

  return (
    <div className="bg-white h-full overflow-y-auto px-4 py-6">
      <h1 className="text-2xl font-bold mb-6">Manga Titles</h1>
      <List>
        {mangaTitles.map((title, index) => (
          <ListItem
            key={index}
            button
            onClick={() => handleMangaClick(title)} // 漫画タイトルクリックで遷移
          >
            <ListItemText
              primary={title}
              primaryTypographyProps={{ fontSize: "18px", fontWeight: "bold", color: "#007bff" }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default MangaListPage;

import React, { FC, useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  AppBar,
  Toolbar as MuiToolbar,
  IconButton,
  Typography,
  Theme,
  useMediaQuery,
  Tooltip,
  TextField,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Menu as MenuIcon, Search as SearchIcon } from "@mui/icons-material";
import { NavigationDrawer } from "../components/NavigationDrawer";
import { AppNavigationList } from "../components/AppNavigationList";
import { useAppContext } from "../context/AppContext";
import { auth } from "../config/firebase";
import LanguageSwitcher from '../components/LanguageSwitcher';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PersonIcon from '@mui/icons-material/Person';

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  padding: theme.spacing(0, 2.5),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 2.5)
  }
}));

export const Layout: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAppContext();
  const isPc = useMediaQuery<Theme>((theme) => theme.breakpoints.up("sm"));
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // 検索キーワードの状態
  const handleLogout = () => {
    auth.signOut();
  };
  
  const handleSearch = (query: string) => {
    if (query.trim()) {
      navigate(`/search/${query.trim()}`); // 検索結果ページに遷移
    }
  };

  // Handle search input for top search bar
  const handleTopSearch = () => {
    handleSearch(searchTerm);
    setSearchTerm("");
  };

  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  // URL changes to close drawer on mobile
  const { pathname } = useLocation();
  useEffect(() => {
    if (!isPc && isOpen) {
      setIsOpen(false);
    }
  }, [pathname, isPc, isOpen]);

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => (isPc ? theme.zIndex.drawer + 1 : undefined) }}>
        <Toolbar>
          <IconButton color="inherit" edge="start" onClick={() => setIsOpen(!isOpen)}>
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            onClick={() => navigate("/")}
            sx={{ cursor: "pointer" }} // クリック可能であることを示す
          >
            MANGA AI CHAT
          </Typography>
          <TextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search Manga or Character..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTopSearch}>
                    <SearchIcon sx={{ color: 'white' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              ml: 2,
              flexGrow: 1,
              "& .MuiInputBase-input::placeholder": {
                color: "white", // プレースホルダーの文字色を灰色に変更
                opacity: 1, // プレースホルダーの不透明度を1に設定
              },
            }}
          />

          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            {user ? (
              <>
                <Tooltip title={user.email} arrow>
                  <IconButton color="inherit">
                    <PersonIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Logout" arrow>
                  <IconButton color="inherit" onClick={handleLogout}>
                    <ExitToAppIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title="Login" arrow>
                  <IconButton color="inherit" onClick={() => navigate("/auth/login")}>
                    <ExitToAppIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Sign In" arrow>
                  <IconButton color="inherit" onClick={() => navigate("/auth/register")}>
                    <AppRegistrationIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {/* <LanguageSwitcher /> */}
          </Box>
        </Toolbar>
      </AppBar>
      <NavigationDrawer
        isOpen={isOpen}
        variant={isPc ? "permanent" : "temporary"}
        PaddingComponent={Toolbar}
        onClose={() => setIsOpen(false)}
      >
        {AppNavigationList}
      </NavigationDrawer>
      <Box sx={{ flex: "1 1 0", display: "flex", flexDirection: "column" }}>
        <Toolbar />
        <Box sx={{ flex: "1 1 auto", padding: 2, overflow: "hidden" }}>
          <Outlet />
        </Box>
      </Box>
      <Box component="footer" sx={{ mt: "auto", py: 2, backgroundColor: "#f0f0f0", textAlign: "center" }}>
        {/* Footer content here if needed */}
      </Box>

      {/* Search bar positioned in the bottom-right corner */}
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          width: "250px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          boxShadow: 3,
          borderRadius: "8px",
          padding: "8px",
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch(searchQuery);
              setSearchQuery("");
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={() => {
                  handleSearch(searchQuery);
                  setSearchQuery("");
                }}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-input::placeholder": {
              color: "grey", // プレースホルダーの文字色を灰色に変更
              opacity: 1, // プレースホルダーの不透明度を1に設定
            },
          }}
        />
      </Box>
    </Box>
  );
};
